<template>
        <base-page>
        <titlu-pagina Titlu="Formular" @on_add_clicked="show_dialog()" :AdaugaVisible='true' />

        <el-card style='margin:5px 0px 5px 0px'>
            <div slot="header" class="clearfix">
                <strong> Filtre </strong>
            </div>
            <div class="filtre">
                <el-form @submit.prevent.native='refresh_info()'>
                    <el-row :gutter="20">
                        
                        <el-col :span='4'>
                            <el-form-item label='Nume' >
                                <el-input v-model='Filters.Nume' />
                            </el-form-item>
                        </el-col>              
                        <el-col :span='4'>
                            <el-form-item label='Stare' prop="Stare">
                                <el-select class='full-width' v-model="Filters.Stare" placeholder="Select">
                                    <el-option label="In lucru"  value="in_lucru"></el-option>
                                    <el-option label="Creat"  value="creat"></el-option>
                                    <el-option label="Solutionat" value="solutionat"></el-option>
                                    <el-option label="Respins"  value="respins"></el-option>
                                    <el-option label="Draft"  value="draft"></el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>              
                        <el-col :md='24' >
                            <el-button @click="reset()" > Reset  </el-button>
                            <el-button type='primary' native-type='submit' @click='refresh_info()'> Aplica </el-button>
                        </el-col>
                    </el-row>
                </el-form>
                </div>
        </el-card>
           
        <el-table :data="Results" :row-class-name="tableRowClassName" v-loading="loading">
            <el-table-column width="150px" prop='NumarInregistrare' label='Numar'></el-table-column>
            <el-table-column prop='NumeFormular' label='Nume formular'></el-table-column>
            <el-table-column prop='DataCreare' label='Data'></el-table-column>
            <el-table-column prop='DataExpirare' label='Data Expirare'></el-table-column>
            <el-table-column prop='Stare' label='Stare'>
                <template slot-scope='scope'>
                    {{ nice_string(scope.row.Stare) }}
                </template>
            </el-table-column>
            <el-table-column fixed="right" label="Actiuni" width="400px" >
            <template slot-scope="scope" >
                <el-tooltip content="Progres Semnaturi">
                    <el-button :disabled="scope.row.Stare == 'draft'" type="info" icon="el-icon-view" plain circle @click="getProgressSemnaturi(scope.row)" />
                </el-tooltip>

                <el-tooltip content="Genereaza PDF">
                    <el-button  type="info" icon="el-icon-download" plain circle @click="genereaza_pdf(scope.row.Id)" />
                </el-tooltip>

                <el-tooltip content="Valideaza">
                    <el-button v-show="(scope.row.Stare == 'draft' && scope.row.IdUtilizator != IdUser && scope.row.DejaValidat == false)" type="success" icon="el-icon-check" circle @click="valideazaFormular(scope.row.Id)" />
                </el-tooltip>

                <el-tooltip content="Modificare">
                    <el-button :disabled="scope.row.Stare != 'creat' && scope.row.Stare != 'draft' && scope.row.Stare != 'respins'" type="primary" icon="el-icon-edit"  circle @click="show_dialog(scope.row.Id)" />
                </el-tooltip>

                <el-tooltip content="Sterge" >
                    <el-button :disabled="scope.row.Stare != 'creat' && scope.row.Stare != 'in_lucru'" type="danger" icon="el-icon-delete" circle @click="delete_item(scope.row)" />
                </el-tooltip>

                <el-tooltip content="Documente" >
                    <el-button :disabled="scope.row.Stare != 'creat'" icon="el-icon-folder-opened" circle @click="uploadDocs(scope.row.Id)" />
                </el-tooltip>

                <el-tooltip v-if="scope.row.Stare == 'creat'" content="Trimitere" >
                    <el-button type="warning" icon="el-icon-right" circle @click="confirmaTrimitere(scope.row.Id)" />
                </el-tooltip>

                <!-- <el-tooltip content="PDF" >
                    <el-button type="success" icon="el-icon-view" circle @click="generatePdf(scope.row.Id)" />
                </el-tooltip> -->

            </template>
        </el-table-column>
    </el-table>
    <el-dialog
        title="Progres semnaturi"
        :visible.sync="showProgressDialog"
        width="50%">
        <div class="container-fluid">
            <el-steps :active="active" finish-status="success" align-center="">
                <el-step v-for="step in AvizariFormular" :status="step.Respins == true ? 'error' : ''" :description="step.Respins == true ? 'Motiv: ' + step.MotivRespingere : ''"  :title="step.NumeFunctie" :key="step.Id"></el-step>
            </el-steps>
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="showProgressDialog = false">Ok</el-button>
        </span>
    </el-dialog>
    
    <el-pagination @size-change="refresh_info" @current-change= "refresh_info" :page-size.sync="PaginationInfo.PerPage" :current-page.sync="PaginationInfo.Page" :total="PaginationInfo.RowCount" layout="pager" />
    <Formular-dialog ref='dlg' @save="refresh_info()" />
    <UploadDocumente ref="uploadDoc"/>	
    <ConfirmValidation ref="confirmValidation" @saved='validation_confirmed'/>
    <Registratura-dialog ref='regDlg' @saved="registrationConfirmed" @refresh="refresh_info()" />

</base-page>
</template>

<script>
import settings from "@/backend/LocalSettings";
import BasePage from "@/pages/BasePage";
import Formular_dialog from '@/pages/formular/Formular_dialog.vue';
import TitluPagina from '@/widgets/TitluPagina';
import UploadDocumente from '@/components/UploadDocumente';
import ConfirmValidation from '@/pages/formular/ConfirmValidation_dialog';
import Registratura_dialog from '@/pages/registratura/Registratura_dialog.vue';

export default {
    Nume: "formular",
    extends: BasePage,
    components: {
        'base-page': BasePage,
        'Formular-dialog': Formular_dialog,
        'titlu-pagina': TitluPagina,
        'Registratura-dialog': Registratura_dialog,
        UploadDocumente,
        ConfirmValidation
    },
    data () {
        return {
            Results: [],
            base_url: '',
            Info: { tipuri_formulare: [] },                            
            Filters: {Nume: '', IdTipContract:'-1', Stare:''},                
            OrderBy: { },
            TempIdForm: -1,
            IdUser: -1,
            showProgressDialog: false,
            PaginationInfo: { Page: 1, PerPage: 50, RowCount: 0, PageSizes: [10, 25, 50, 100, 200] },
            loading: true,
            active: 2,
            AvizariFormular: [],
            htmlPreview: '',
            numarRegistratura: ''
        }
    },
    methods: {
        tableRowClassName({row}) {
            if (row.Stare == 'creat') 
                return '';
            if (row.Stare == 'in_lucru') 
                return 'warning-row';
            if (row.Stare == 'respins') 
                return 'danger-row';
            return 'success-row';
        },
        async get_info(){
            var response = await this.post("formulare/get_info" );
            this.Info.tipuri_formulare = response.tipuri_formulare
            this.refresh_info();
        },
        async refresh_info(){
            var response        = await this.post("formulare/index", { Filters: this.Filters, OrderBy: this.OrderBy, PaginationInfo: this.PaginationInfo } );
            this.Results        = response.Results;
            this.PaginationInfo = response.PaginationInfo;
            this.loading        = false
            //
            this.select_menu_item('formular');
        },
        async genereaza_pdf(id){
            console.log('genereaza pdf');
            // window.open("http://ananp.brk-dev.ro/api/index.php/formulare/generate_pdf/" + id, '_blank');
            window.open(settings.BASE_URL + "formulare/generate_pdf/" + id, '_blank');
        },

        valideazaFormular(id){
            this.$confirm('Esti sigur ca vrei sa validezi formularul?', 'Warning', {
                confirmButtonText: 'Confirm',
                cancelButtonText: 'Cancel',
                type: 'warning'
            }).then(() => {
                //aici trebuie marcat ca validat de catre utilizator
                let res = this.post('Formulare/valideaza_formular', {
                    idFormular: id
                })
                this.refresh_info();
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: 'Validare anulata'
                });          
            });
        },

        reset(){
            this.Filters = {Nume: '', IdTipContract:'-1', Stare: ''};                
            this.refresh_info();
        },
        async getProgressSemnaturi(formular) {
            let id = formular.Id;
            const response = await this.$http.post('formulare/get_status_semnaturi', {idFormular: id});
            this.AvizariFormular = response.body.Results;
            // this.htmlPreview = formular.Continut;

            this.numaraSemnaturi()
            this.showProgressDialog = true;
        },
        numaraSemnaturi(){
            let contor = 0;
            let continua = true;
            this.AvizariFormular.forEach((avizare) => {
                if(avizare.Status != 'validat' && continua){
                    contor = parseInt(avizare.NumarOrdine) - 1;
                    continua = false;
                };
            });       
            if(continua){
                this.active = this.AvizariFormular.length;
            }else {
                this.active = contor;
            }
            
        },
        async delete_item( item ){
           this.$confirm(`Sunteti sigur ?`, 'Warning', {               
                type: 'warning'
                }).then(async() => {
                    await this.post("formulare/delete_item", { id: item.Id } );
                        this.refresh_info(); 
                        this.$message({type: 'info', message: 'Stergere efectuata cu succes'});                                                                       
                        }).catch(() => {
                            this.$message({type: 'info', message: 'Stergere anulata'});                                                                 
                            });
        },
        show_dialog(id){
            this.$refs.dlg.show_me(id, 'edit');
        },
        generatePdf(id) {
        //   window.open("http://ananp.brk-dev.ro/api/index.php/formulare/generate_pdf/" + id, '_blank');
          window.open(settings.BASE_URL + "formulare/generate_pdf/" + id, '_blank');
        },
        uploadDocs(id){
            this.$refs.uploadDoc.showMe(id)
        },
        async confirmaTrimitere(id){
            this.TempIdForm = id;
            await this.$refs.regDlg.show_me( null, "formular", id);
        },
        async registrationConfirmed(numar){
            this.numarRegistratura = numar;
            // console.log('registrationConfirmed',this.numarRegistratura );
            // return
            var all_steps = await this.getAllStepsRezolvare(this.TempIdForm);
            // var next_step   = await this.getNextStepRezolvare(id);           
            // this.$refs.confirmValidation.show_me(id, next_step.id, next_step.name, next_step.utilizatori)
            await this.$refs.confirmValidation.show_me(this.TempIdForm, all_steps);            
        },
        validation_confirmed(){
            // console.log('numar primit',item);
            // return
            // this.numarRegistratura = item
            this.updateNumarFormular();
            this.$message({type: 'success', message: 'Trimitere efectuata cu succes'});
            this.refresh_info();
        },

        async updateNumarFormular(){
            var response = await this.post('formulare/update_numar', {form_id: this.TempIdForm, numar_registratura: this.numarRegistratura})
        },

        async getAllStepsRezolvare(id_formular){
            let res = await this.post('formulare/get_all_steps_rezolvare', {idFormular: id_formular});
            return res.Avizari;
        },

        async getNextStepRezolvare(id_formular){
            var result = await this.post('formulare/get_next_step_rezolvare', {idFormular: id_formular});
            return {id:result.next_id_intrare, name: result.next_function_name, utilizatori: result.next_utilizatori}
        },
        nice_string(text){
            var ret = text.split("_")
            ret[0]  = ret[0].charAt(0).toUpperCase() + ret[0].slice(1)
            return ret.join(" ")
        }        
    },
    mounted(){
        this.base_url = settings.BASE_URL;
        this.IdUser = settings.get_user_id();
        this.get_info();        
    }
};
</script>

<style lang="less">

.top50{
  margin-top: 20px;
}

.filtre{
  .el-input-number
  {
    width: 100% !important;
  }
}
.el-table .warning-row {
    background: oldlace;
}
.el-table .success-row {
    background: #f0f9eb;
}
.el-table .danger-row {
    background: #f05b6c;
}
.el-table .primary-row{
    background: #8cc4ff;
}

</style>
