<template>
  	<el-dialog title="Trimite spre rezolvare catre:" width="30%" :visible.sync="showPopup" class="my-dialog-class" :close-on-click-modal='true'>
		<el-form label-position="top" :inline="false" label-width="100%" ref='confirmValidation' @submit.prevent="save" v-loading="loadingVisible" >
			<el-row>
				<el-col :span="20" :offset="2"> 
					<el-form-item v-for="avizare in Avizari" :key="avizare.Id" :label="'Angajat - ' + avizare.function_name" >
						<el-select class='full-width' v-model="avizare.IduriAngajati" :disabled="avizare.utilizatori.length == 0 ? true : false" >
							<el-option v-for="item in avizare.utilizatori" :key="item.Id" :label="item.Nume" :value="item.Id" > </el-option>
						</el-select>
					</el-form-item>
				</el-col>
			</el-row>
		</el-form>

		<span slot="footer" class="dialog-footer">
			<el-button @click="validareAnulata()" > Renunta  </el-button>
			<el-button v-if="semnaturaValidata" type="success" @click="save()"> Trimitere </el-button>
			<el-button v-else type="success" @click="semneaza()"> Semneaza </el-button>
		</span>
		<el-dialog title="Login" :visible.sync="showPopupSemnatura" width="30%" :append-to-body="true">			
			<div v-if="eroare_semnatura">
				<span class="eroare">Nu exista semnatura incarcata</span>
			</div>
			<div v-else>
				<img width="70%" :src="imagineSemnatura" />			

				<el-form :inline="true" :model='obiectParola' ref='verificareParola' @submit.prevent="verificaParola" v-loading="loader" >					
					<el-form-item label="Parola">
						<el-input type="password" v-model="obiectParola.password" placeholder='Parola dvs' class='full-width'></el-input>
					</el-form-item>
					<el-form-item>
						<el-button type="primary" @click="verificaParola"> Valideaza semnatura </el-button>
					</el-form-item>					
				</el-form>
			</div>
		</el-dialog>
  	</el-dialog>
	  
</template>

<script>

import BasePage from '@/pages/BasePage'

export default {
    Nume: "ConfirmValidation",
    extends: BasePage,    
    components: {
      
    },		
	computed:{
		
	},
    data () {
      	return {
			showPopup: false,
			selectedObject: { IduriAngajati: [] },
			Avizari: [],
			Angajati: [],
			idUser: -1,
			id_formular: '',
			showPopupSemnatura: false,
			loader: false,
			obiectParola: {password:''},
			eroare_semnatura: false,
			imagineSemnatura: '',
			semnaturaValidata: false,
			id_form_avizare: '',
			functie_angajati: '',
      }
    },
    methods: {
        // show_me: async function(idFormular, idFormularAvizare, functieAngajati, intrariAngajati ) {
        //   	this.selectedObject		= { IduriAngajati: [] }
		// 	console.log(intrariAngajati)
        //   	this.Angajati			= intrariAngajati
		// 	this.id_formular		= idFormular
		// 	this.id_form_avizare	= idFormularAvizare
		// 	this.functie_angajati	= functieAngajati
        //     this.showPopup			= true;			
        // },

		show_me: async function(idFormular, avizari ) {
			let res = await this.post('Formulare/get_formular_by_id', {
				idFormular: idFormular
			})
			console.log(res);
			this.selectedObject = res.Formular;

          	this.selectedObject.IduriAngajati = []
			this.Avizari 			= avizari;
			this.id_formular		= idFormular;
            this.showPopup			= true;
			console.log(this.Avizari);
        },

		resetForm(){
			this.showPopup = false
		},

		async verificaParola(){
			var result = await this.post('utilizatori/verifica_parola', {password: this.obiectParola.password})
			if(!result.Error){
				this.setezaIdSemnatura()
				this.showPopupSemnatura = false
			}
			else
				this.$message.error('Parola gresita');
		},

		setezaIdSemnatura(){
			this.semnaturaValidata = true;
			console.log(this.selectedObject);	
			this.selectedObject.Variabile = JSON.parse(this.selectedObject.Variabile)
			this.selectedObject.Variabile.forEach(v=>{
				if(v.Nume == 'semnatura')
					v.Value = this.idUser
			})

			this.selectedObject.Variabile = JSON.stringify(this.selectedObject.Variabile);
		},

		async semneaza(){
			this.showPopupSemnatura	= true
			this.loader 			= true
			var response 			= await this.verificaSemnatura()
			this.imagineSemnatura	= response.Semnatura
			this.idUser				= response.UserId

			if(response.Error)
				this.eroare_semnatura = true

			console.log(this.imagineSemnatura);
			this.loader = false
		},
		async verificaSemnatura(){
			var result 		= await this.post('utilizatori/verifica_semnatura');
			if(result.Error)
                this.eroare_semnatura = true

			return result
		},

		async save(){
			let res = await this.post('formulare/update_variabile', {
				selectedObject: this.selectedObject
			});

			var result = await this.post('formulare/trimitere_spre_rezolvare',{ idFormular: this.id_formular, avizari: this.Avizari });
			// let status = result.status;
			// if (status !== "succes") {
			// 	if (status[0] === "email-invalid") {
			// 		this.$message({type: "error", message: `Email-ul utilizatorului ${status[1]} este invalid (${status[2]})`});
			// 	} else if (status[0] === "fara-angajat") {
			// 		this.$message({type: "error", message: `${status[0]} nu are un angajat asociat`});
			// 	}
			// } else {
			this.$message({type: 'success', message: 'Trimitere efectuata cu succes'});
			this.$emit('saved');
			this.resetForm();
			// }
		},
		validareAnulata(){
			this.semnaturaValidata = false;
			this.$message({type: 'info', message: 'Trimitere anulata'});
			this.resetForm();
		}
    },
    mounted: function() {

	}
};
</script>

<style lang="less" scoped>
.full-width {
  width: 100%;
}
.alerta{
	color: red;
	font-size: 15px;
	margin-right: 10px;
}

.image_preview{
	width: 150px;
	display: block;
	border: solid 1px grey;
}

</style>